import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const FaqListSideBarWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FaqSideList = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Gotham HTF Book";
  margin: 0px;
  position: relative;
  width: 100%;

  > a.active {
    background: #eaeaea;
    opacity: 1;
  }
`

const FaqSideListTitleLink = styled(Link)`
  font-family: "Gotham HTF Book";
  margin: 0px 0px 5px 0px;
  position: relative;
  padding: 10px;
  background: transparent;
  opacity: 0.5;

  :hover {
    background: #eaeaea;
    opacity: 1;
  }
`

const FaqSideListTitle = styled.span`
  font-family: "Gotham HTF Book";
`

const FaqCategorySideBar = ({ intl, data, sidebarKey }) => {
  return (
    data && (
      <FaqListSideBarWrapper>
        {data.map((item, index) => {
          return (
            item &&
            item.key &&
            item.title && (
              <FaqSideList key={index.toString()}>
                <FaqSideListTitleLink
                  to={item.link}
                  className={sidebarKey && item.key === sidebarKey && "active"}
                >
                  <FaqSideListTitle
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                </FaqSideListTitleLink>
              </FaqSideList>
            )
          )
        })}
      </FaqListSideBarWrapper>
    )
  )
}

export default injectIntl(FaqCategorySideBar)
